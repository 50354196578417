import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { User } from '@/types/account/user'

const ls = new SecureLS({ isCompression: false })

export const authStore = defineStore('auth',
  () => {
    const token = ref(ls.get('token') || null)
    const user = ref(ls.get('user') || null)
    const loggedIn = computed(() => !!token.value)
    const updating = ref(false)
    const demoEnvironment = ref(ls.get('demo') || false)
    const selectedBuildingId = ref<number|null>(ls.get('bid') || false)

    function login (username: string, password: string) {
      return axios.post(process.env.VUE_APP_API_URL + '/login', {
        username: username,
        password: password
      }).then((res) => {
        // Set token.
        token.value = 'Bearer ' + res.data.access_token
        ls.set('token', token.value)

        // Set bearer token for axios calls.
        axios.defaults.headers.common.Authorization = token.value

        axios.get(process.env.VUE_APP_API_URL + '/users/buyersPortal/settings').then((r) => {
          if (r.data.length) {
            r.data.forEach((setting: { id: number, setting_name: string, setting_value: never }) => {
              new SecureLS({ isCompression: false }).set(setting.setting_name, setting.setting_value)
            })
          }
        })

        return new Promise<void>((resolve, reject) => {
          axios.get(process.env.VUE_APP_API_URL + '/user').then((r) => {
            user.value = r.data
            ls.set('user', user.value)

            if (user.value.buildings.length) {
              setSelectedBuildingId(user.value.buildings[0].id)
              ls.set('buildings', user.value.buildings)
            }

            ls.set('details', user)
            resolve()
          }).catch((e) => {
            reject(e)
          })
        })
      })
    }

    function logout (forcedLogout = false) {
      axios.get(process.env.VUE_APP_API_URL + '/logout').finally(() => {
        // Clear token and localstorage.
        token.value = null
        clearLS()

        // Remove bearer token for axios calls.
        delete axios.defaults.headers.common.Authorization

        if (forcedLogout) {
          router.push('/').then(() => {
            user.value = null
            notification.info({
              message: 'Sessie verlopen!.',
              description: 'Uw sessie is verlopen en u bent automatisch uitgelogd. Om verder te gaan kunt u opnieuw inloggen.',
              duration: 5
            })
          })
        } else {
          router.push('/').then(() => {
            user.value = null
            notification.success({
              message: 'Succesvol uitgelogd.',
              description: 'U bent succesvol uitgelogd. U kunt dit scherm nu sluiten.',
              duration: 5
            })
          })
        }
      })
    }

    function updateUser (userToUpdate: User) {
      return new Promise<void>((resolve, reject) => {
        updating.value = true
        axios.post(process.env.VUE_APP_API_URL + '/user', userToUpdate).then((r) => {
          user.value = r.data
          ls.set('user', user.value)
          notification.success({
            message: 'Gegevens gewijzigd.',
            description: 'Uw gegevens zijn succesvol gewijzigd.',
            duration: 5
          })
          resolve()
        }).catch((e) => {
          reject(e)
          notification.error({
            message: 'Gegevens NIET gewijzigd.',
            description: 'Uw gegevens zijn NIET gewijzigd. Probeer het later nog eens.',
            duration: 5
          })
        }).finally(() => {
          updating.value = false
        })
      })
    }

    function forgotPassword (email: string): void {
      axios.post(process.env.VUE_APP_API_URL + '/password/reset', { app: 'buyer-portal', email: email }).then(() => {
        notification.success({
          message: 'Wachtwoord herstel aangevraagd.',
          description: 'Er is een e-mail met verdere instructies verzonden naar het door u opgegeven e-mailadres.',
          duration: 5
        })
        router.push({ name: 'Login' })
      }).catch(() => {
        notification.error({
          message: 'Wachtwoord herstellen mislukt!',
          description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
          duration: 0
        })
      })
    }

    function resetPassword (data: {
        email: string | null,
        activationKey: string | null,
        password: string | null,
        passwordConfirmation: string | null
      }): void {
      axios.post(process.env.VUE_APP_API_URL + '/activate/user', data).then((r) => {
        if (r.status === 200 && r.data !== 'Account already activated!') {
          notification.success({
            message: 'Account geactiveerd.',
            description: 'Uw account is succesvol geactiveerd. U kunt nu inloggen met het door u gekozen wachtwoord.',
            duration: 5
          })
          router.push({ name: 'Login' })
        }
      }).catch((e) => {
        if (e.response.data === 'Already activated.') {
          notification.warning({
            message: 'Account activeren mislukt!',
            description: 'De door u gebruikte persoonlijke activatiecode is al eens gebruikt. Vraag een nieuwe persoonlijke activatiecode aan.',
            duration: 0
          })
          router.push({ name: 'ForgotPassword' })
        } else {
          notification.error({
            message: 'Account activeren mislukt!',
            description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
            duration: 0
          })
        }
      })
    }

    function setDemoEnvironment (demo: boolean): void {
      demoEnvironment.value = demo
      ls.set('demo', demo)
    }

    function setSelectedBuildingId (buildingId: number): void {
      selectedBuildingId.value = buildingId
    }

    function clearLS (): void {
      const lsKeys = ls.getAllKeys()
      const lsKeysNotToClear = ['sales_activated', 'pre-selection_activated', 'development_activated', 'delivery_activated', 'service_activated', 'woononVideoShown']

      lsKeys.forEach((lsKey: string) => {
        if (!lsKeysNotToClear.includes(lsKey)) {
          ls.remove(lsKey)
        }
      })
    }

    return {
      user,
      loggedIn,
      updating,
      demoEnvironment,
      selectedBuildingId,
      login,
      logout,
      updateUser,
      forgotPassword,
      resetPassword,
      setDemoEnvironment,
      setSelectedBuildingId
    }
  })
