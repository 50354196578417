import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import { buildingStates } from '@/globalVariables'

const ls = new SecureLS({ isCompression: false })

export const headerStore = defineStore('header', () => {
  const ordersReceived = ref(ls.get('ordersReceived') || {
    modular: false,
    carcass: false,
    completion: false,
    extra: false
  })

  const extraPhaseEnabled = ref(ls.get('extraPhaseEnabled') || false)

  const buildingSold = ref(ls.get('buildingSold') || false)

  const buildingDelivered = ref(ls.get('buildingDelivered') || false)

  const serviceEnabled = computed(() => {
    return (buildingSold.value && !buildingDelivered.value) || buildingDelivered.value
  })

  function getOrdersReceived () {
    axios.get(process.env.VUE_APP_API_URL + '/order/states').then((r) => {
      ordersReceived.value = r.data
      ls.set('ordersReceived', r.data)
    })
  }

  function resetOrdersReceived () : void {
    ordersReceived.value = { modular: false, carcass: false, completion: false, extra: false }
    ls.set('orderReceived', ordersReceived.value)
  }

  function checkIfExtraPhaseIsEnabled () : void {
    axios.get(process.env.VUE_APP_API_URL + '/extra/state').then((r) => {
      extraPhaseEnabled.value = r.data
      ls.set('extraPhaseEnabled', r.data)
    })
  }

  function disableExtraPhase () : void {
    extraPhaseEnabled.value = false
    ls.set('extraPhaseEnabled', false)
  }

  function checkIfServiceIsEnabled () : void {
    buildingSold.value = [buildingStates.SOLD, buildingStates.SOLD_VERBALLY, buildingStates.RENTED].includes(ls.get('building').state)
    buildingDelivered.value = ls.get('building').delivered
    ls.set('buildingSold', buildingSold.value)
    ls.set('buildingDelivered', buildingDelivered.value)
  }

  function disableService () : void {
    buildingSold.value = false
    buildingDelivered.value = false
    ls.set('buildingSold', buildingSold.value)
    ls.set('buildingDelivered', buildingDelivered.value)
  }

  return { ordersReceived, extraPhaseEnabled, buildingSold, buildingDelivered, serviceEnabled, getOrdersReceived, resetOrdersReceived, checkIfExtraPhaseIsEnabled, disableExtraPhase, checkIfServiceIsEnabled, disableService }
})
