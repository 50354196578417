export const buildingStates = Object.freeze({
  TO_BE_SOLD: 0,
  FOR_SALE: 1,
  SOLD: 2,
  SOLD_VERBALLY: 3,
  OPTIONAL: 4,
  FOR_RENT: 5,
  RENTED: 6
})

export const IssueTypes = Object.freeze({
  ISSUE: 1,
  OBJECTION: 2,
  QUESTION: 3,
  DELIVERY: 4,
  RENTAL: 7
})

export const IssueStatusColors = Object.freeze({
  0: '#2ea3f2',
  1: 'blue',
  2: '#acca91',
  3: '#6ba200',
  4: '#5d6d7e',
  5: '#c7000a',
  6: '#2ea3f2',
  7: 'green',
  8: '#dbdbdb',
  9: '#dbdbdb',
  10: '#faad14',
  11: 'blue',
  12: 'blue',
  13: 'red',
  14: '#acca91',
  15: '#acca91'
})

export const EmptyWallOutlets = ['70110', '70210']
